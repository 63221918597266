<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Nuevo"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
               
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Clientes"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Usuarios</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>
<!-- 
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
          <Column
            field="name"
            header="Nombre"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.name }}
            </template>
          </Column>
          
          <Column
            field="email"
            header="E-mail"
            :sortable="true"
            headerStyle=""
          >
            <template #body="slotProps">
              <span class="p-column-title">E-mail</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
            <Column
            field="name"
            header="Nombre de Usuario"
            :sortable="true"
            headerStyle=" min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre de Usuario</span>
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column
            field="location"
            header="Grupo"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Grupo</span>
              {{ giveMeGroupName(slotProps.data.groups[0])}}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px' }"
          header="Usuario"
          :modal="true"
          class="p-fluid"
        >
          <img
            :src="'images/product/' + product.image"
            :alt="product.image"
            v-if="product.image"
            width="150"
            class="mt-0 mx-auto mb-5 block shadow-2"
          />
          <div class="field">
            <label for="name">Nombre</label>
            <InputText
              id="name"
              v-model.trim="product.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.name }"
            />
            <small class="p-invalid" v-if="submitted && !product.name"
              >Nombre es requerido.</small
            >
          </div>
          <div class="field">
            <label for="email">E-mail</label>
            <InputText
              id="email"
              v-model.trim="product.email"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.email }"
            />
            <small class="p-invalid" v-if="submitted && !product.email"
              >E-mail es requerido.</small
            >
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <label for="username">Nombre de Usuario</label>
              <InputText
                id="username"
                v-model.trim="product.username"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !product.username }"
              />
              <small class="p-invalid" v-if="submitted && !product.username"
                >Nombre de Usuario es requerido.</small
              >
            </div>
            <div class="field col">
              <label for="password">Password</label>
                        <Password id="password1" v-model="product.password" 
                        placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>

              <small class="p-invalid" v-if="submitted && !product.iibb"
                >Password es requerido.</small
              >
            </div>
          </div>
          
          <div class="field">
            <label class="mb-3">Grupo</label>
            <div class="formgrid grid">
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client1"
                  name="type_client"
                  value="1"
                  v-model="product.type_client"
                />
                <label for="type_client1">Gerencia</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client2"
                  name="type_client"
                  value="2"
                  v-model="product.type_client"
                />
                <label for="type_client2">Producción</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client4"
                  name="type_client"
                  value="4"
                  v-model="product.type_client"
                />
                <label for="type_client4">Operarios</label>
              </div>
              <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client5"
                  name="type_client"
                  value="5"
                  v-model="product.type_client"
                />
                <label for="type_client5">Calidad</label>
              </div>
                            <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client6"
                  name="type_client"
                  value="3"
                  v-model="product.type_client"
                />
                <label for="type_client6">Ingeniería</label>
              </div>
                <div class="field-radiobutton col-6">
                <RadioButton
                  id="type_client7"
                  name="type_client"
                  value="7"
                  v-model="product.type_client"
                />
                <label for="type_client7">Mantenimiento</label>
              </div>
            </div>
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmación"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Eliminaras a <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct(product.id)"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product">Seguro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import CrudService from "../services/crud.service";

export default {
  data() {
    return {
      routeName: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      validationErrors: null,
      groupName: [
        { label: "Gerencia", value: 1 },
        { label: "Operarios", value: 2 },
        { label: "Calidad", value: 3 },
        { label: "Ingeniería", value: 6 },
        { label: "Mantenimiento", value: 7 }
      ],
    };
  },
   
  created() {
    // get route name
    this.routeName = this.$route.name;
     
    this.initFilters();
  },
  mounted() {
     CrudService.getCRUD(this.routeName).then(
      (data) => (this.products = data)
    );
  },
  methods: {
    afipType(value) {
      if (value == 1) {
        return "Responsable Inscripto";
      }
      if (value == 2) {
        return "Monotributista";
      }
      if (value == 3) {
        return "Exento";
      }
      if (value == 4) {
        return "Consumidor Final";
      }

      return " - ";
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
},
    saveProduct() {
      this.submitted = true;
      if (this.product && this.product.name) {
        if (this.product.id) {
          this.product.csrfmiddlewaretoken = this.getCookie('csrftoken');
          this.product.csrftoken = this.getCookie('csrftoken');
          this.product.groups = parseInt(this.product.type_client);
          CrudService.updateCRUD('admin', 'users/user', this.product, 'change').then(
            (data) => {

            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Actualizado correctamente.",
                life: 3000,
              });
              this.products[this.findIndexById(this.product.id)] = this.product;
              this.productDialog = false;
              this.product = {};
            }
          });
        } else {
          CrudService.createCRUD(this.routeName, this.routeName, this.product).then(
            (data) => {
 
            this.validationErrors = null;
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
                this.products.push(this.product);
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                this.productDialog = false;
                this.product = {};
              }
            }
          );
        }
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct(id) {

      console.log(id)
      
      CrudService.deleteCRUD(this.routeName, this.routeName, this.product.id).then(
        (data) => {

          this.products = this.products.filter((val) =>{ 
            console.log(val)
            return val.id !== id
          });

          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Eliminado correctamente.",
            life: 3000,
          });
        }
      );

      this.deleteProductDialog = false;
      this.product = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Éxito!",
        detail: "Eliminado correctamente.",
        life: 3000,
      });
    },
    giveMeGroupName(id) {
      let exist = this.groupName.find(group => group.value === id);

      if(!exist) {
        return ' - ';
      }else {
        return exist.label;
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
